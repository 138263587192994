*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Open Sans", sans-serif;
  position: relative;
  height: 100%;
}
ul {
  list-style: none;
  padding: 0;
}
a {
  text-decoration: none;
}

html {
  height: 100%;
}